// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-club-chelero-js": () => import("./../src/pages/club-chelero.js" /* webpackChunkName: "component---src-pages-club-chelero-js" */),
  "component---src-pages-estado-quintanaroo-js": () => import("./../src/pages/estado/quintanaroo.js" /* webpackChunkName: "component---src-pages-estado-quintanaroo-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-promociones-js": () => import("./../src/pages/promociones.js" /* webpackChunkName: "component---src-pages-promociones-js" */),
  "component---src-pages-tulum-js": () => import("./../src/pages/tulum.js" /* webpackChunkName: "component---src-pages-tulum-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

